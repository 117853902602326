<template>
	<v-dialog v-model="dialog" content-class="mw-medium-dialog">
		<template v-slot:activator="{ on }">
			<v-btn icon v-on="on">
				<v-icon color="ig-black">mdi-format-list-checks</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<v-list>
					<v-list-item>
						<v-list-item-content
							><i>{{ goal.name }}</i></v-list-item-content
						>
						<v-list-item-action>
							<v-checkbox
								@change="selectAll"
								:indeterminate="indeterminate"
							></v-checkbox>
						</v-list-item-action>
					</v-list-item>
					<v-list-item
						v-for="part in partsThatCouldHaveThisGoal"
						:key="part.part"
					>
						<v-list-item-content>
							<p>
								<b
									>{{ $t("departments.nav.single") }}:
									{{ departments[part.department].name }}</b
								>
								{{ $t("goal_groups.nav.single") }}:
								{{ partNames[part.part] }}
							</p>
						</v-list-item-content>
						<v-list-item-action>
							<v-checkbox
								:disabled="disabled(part.department)"
								:value="part"
								v-model="selected"
							>
							</v-checkbox>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="save" color="primary">{{
					$t("buttons.save")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less"></style>
<script type="text/javascript">
import Vuex from "vuex";
// Pop up dialog from which users can select / deselect goals from all goal groups
// @group Config
export default {
	name: "ConfigGoalMultiple",
	props: {
		//id of the goal
		id: { type: String }, 
	},
	data: () => {
		return {
			dialog: false,
			selected: [],
		};
	},
	computed: {
		...Vuex.mapState({
			goalGroups: (state) => state.goalGroups.data,
			departments: (state) => state.departments.data,
			sectionParts: (state) => state.sectionParts.data,
			sectionResponses: (state) => state.sectionResponses.data,
			goals: (state) => state.goals.data,
		}),
		config() {
			return this.$store.getters["organisation/config"];
		},
		partNames() {
			var self = this;
			return Object.fromEntries(
				Object.values(self.sectionParts).map((p) => {
					var name = self.goalGroups[p.goalGroup]
						? self.goalGroups[p.goalGroup].name
						: "";
					return [p.id, name];
				})
			);
		},
		defaultConfig() {
			return this.$store.getters["defaultConfig"];
		},
		goal() {
			return this.goals[this.id];
		},
		partsThatHaveThisGoal() {
			const self = this;
			return Object.keys(self.config)
				.flatMap((department) => {
					return Object.keys(self.config[department]).flatMap((part) => {
						if (self.config[department][part].includes(self.id)) {
							return { part, department };
						}
					});
				})
				.filter((a) => a);
		},
		partsThatCouldHaveThisGoal() {
			const self = this;
			return Object.keys(self.config)
				.flatMap((department) => {
					return Object.keys(self.defaultConfig[department]).flatMap(
						(part) => {
							if (
								self.defaultConfig[department][part] && self.defaultConfig[department][part].includes(self.id)
							) {
								return { part, department };
							}
						}
					);
				})
				.filter((a) => a);
		},
		indeterminate() {
			return (
				this.selected.length > 0 &&
				this.selected.length < this.partsThatCouldHaveThisGoal.length
			);
		},
		deselected() {
			return this.partsThatHaveThisGoal.filter(
				(a) => !this.selected.includes(a)
			);
		},
	},
	methods: {
		selectAll(checked) {
			var self = this;
			var canNotAdd = self.partsThatCouldHaveThisGoal.filter((a) =>
				self.disabled(a.department)
			);
			var canNotRemove = self.partsThatHaveThisGoal.filter((a) =>
				self.disabled(a.department)
			);

			if (checked) {
				self.selected = self.partsThatCouldHaveThisGoal.filter(
					(a) => !canNotAdd.includes(a)
				);
			} else {
				console.log(canNotRemove);
				self.selected = canNotRemove;
			}
		},
		disabled(department) {
			var map = this.$store.getters.responseMap;
			var response = map[department];
			if (!response) {
				return false;
			}
			return ["delegated", "complete"].includes(response.status);
		},
		save() {
			var self = this;
			self.selected.forEach((selected) => {
				self.$store.dispatch("organisation/addGoalToConfig", {
					...selected,
					goal: self.id,
				});
			});
			self.deselected.forEach((selected) => {
				self.$store.dispatch("organisation/removeGoalFromConfig", {
					...selected,
					goal: self.id,
				});
			});
			this.dialog = false;
		},
	},
	created() {
		this.selected = this.partsThatHaveThisGoal;
	},
};
//
</script>
"
