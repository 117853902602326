<template>
	<v-sheet>
		<p>
			{{ $t("config.department_selection_intro") }}
		</p>
		<v-list dense class="mt-7">
			<v-item-group multiple v-model="departmentsInConfig">
				<template v-for="id in myDepartments">
					<department-selection-list-item
						:key="id"
						@error="error(id)"
						:id="id"
					></department-selection-list-item>
				</template>
			</v-item-group>
		</v-list>
		<p class="text-right">
			<v-btn @click="save" color="primary">{{ $t("buttons.save") }}</v-btn>
		</p>
	</v-sheet>
</template>
<script>
import DepartmentSelectionListItem from "./DepartmentSelectionListItem.vue";
// A list of available departments
// @group Config
export default {
	name: "DepartmentSelectionList",
	data: () => {
		return {
			departmentsInConfig: [],
		};
	},
	components: { DepartmentSelectionListItem },
	computed: {
		myDepartments(){
			return this.$store.getters["departments/onlyMineOrder"];
		},
		haveConfig() {
			return this.$store.getters["organisation/haveConfig"];
		},
		config() {
			return this.$store.getters["organisation/config"];
		},
		added() {
			const self = this;
			if (!self.haveConfig) {
				return self.departmentsInConfig;
			}
			var configured = Object.keys(self.config);
			return self.departmentsInConfig.filter((v) => !configured.includes(v));
		},
		removed() {
			const self = this;
			if (!self.haveConfig) {
				return [];
			}
			var configured = Object.keys(self.config);
			return configured.filter((v) => !self.departmentsInConfig.includes(v));
		},
	},
	methods: {
		async deleteRemoved() {
			var self = this;
			var batch = self.removed.map(
				(key) => `assessmentConfig.sections.${key}`
			);
			return await self.$store.dispatch("organisation/deleteBatch", batch);
		},
		async saveAdded() {
			return await this.$store.dispatch(
				"organisation/addSectionsToConfig",
				this.added
			);
		},
		save() {
			var self = this;
			Promise.all([self.deleteRemoved(), self.saveAdded()]).then(() => {
				self.saved = true;
				self.$router.replace("/assessment-config/sections");
			});
		},
	},
	watch: {
		config: {
			immediate: true,
			handler(value) {
				if (value) {
					if (!this.departmentsInConfig.length) {
						this.departmentsInConfig = Object.keys(this.config);
					}
				}
			},
		},
	},
	created() {
		this.$store.dispatch("startConfig");
	},
};
</script>
