<template>
	<v-select
		multiple
        label="Tags"
		chips
		:items="items"
		:value="value"
        color="primary"
		clearable
		@input="select"
	></v-select>
</template>

<script>
import Vuex from "vuex";
// Filters a provided array by available tags
// @group Config
export default {
	name: "FilterByTags",
	props: {
		value: { type: Array },
	},
	computed: {
		...Vuex.mapState({
			tags: (state) => state.tags.data,
		}),
		items() {
			return this.mwutilities.itemsArray(this.tags);
		},
	},
	methods: {
		select(value) {
			this.$store.commit("SET_FILTER", value);
		},
	},
};
</script>
