<template>
	<v-sheet flat>
		<v-card flat color="secondary" v-if="!haveConfig">
			<v-card-text> {{ $t("config.section_intro") }} </v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" to="/assessment-config/departments">{{
					$t("config.nav.department_setup")
				}}</v-btn>
			</v-card-actions>
		</v-card>
		<template v-else>
			<v-card flat v-if="!currentDepartment">
				<v-card-text> </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="start(firstDepartment)">{{
						$t("config.nav.goal_config")
					}}</v-btn>
				</v-card-actions>
			</v-card>

			<v-card flat v-else>
				<filter-by-tag v-model="filter"></filter-by-tag>
				<section-config-toolbar
					title
					:text="department.name"
					:value="currentDepartment"
					:array="orderedDepartments"
					@click="(d) => start(d)"
				>
					<template v-slot:badge>
						<status-chip
							class="ml-3"
							:department="currentDepartment"
						></status-chip>
					</template>
				</section-config-toolbar>
				<part-config></part-config>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="save">{{
						$t("buttons.save")
					}}</v-btn>
					<v-btn color="primary" class="ml-2" router-link to="/assessment">{{
						$t("nav.start_assessment")
					}}</v-btn>
				</v-card-actions>
			</v-card>
			<saved v-model="saved"></saved>
		</template>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
import Saved from "@c/notices/Saved";
import SectionConfigToolbar from "@c/config/SectionConfigToolbar";
import PartConfig from "@c/config/PartConfig";
import StatusChip from "@c/navigation/assessment-navigation/StatusChip";
import FilterByTag from "@c/config/FilterByTag";
// Lists all sectionParts in the config for a single department
// @group Config
export default {
	name: "SectionConfig",
	data: () => {
		return {
			saved: false,
		};
	},
	components: {
		SectionConfigToolbar,
		PartConfig,
		Saved,
		StatusChip,
		FilterByTag,
	},
	computed: {
		...Vuex.mapState({
			sections: (state) => state.sections.data,
			departments: (state) => state.departments.data,
			currentDepartment: (state) => state.current.department,
			currentPart: (state) => state.current.part,
		}),
		haveConfig() {
			return this.$store.getters["organisation/haveConfig"];
		},
		filter() {
			return this.$store.state.tag_filter;
		},
		config() {
			return this.$store.getters["organisation/config"];
		},
		orderedDepartments() {
			return this.$store.getters["departments/defaultOrderConfigOnly"];
		},
		firstDepartment() {
			return this.orderedDepartments[0];
		},
		department() {
			return this.departments[this.currentDepartment];
		},
		section() {
			var section = this.department.section;
			return this.sections[section];
		},
		partOrder() {
			return this.$store.getters.defaultPartOrder;
		},
	},
	methods: {
		start(department) {
			if (!this.haveConfig) {
				return false;
			}
			var section = this.departments[department].section;
			var part = this.partOrder[section][0];
			var to = { part, department };
			this.$store.commit("GO_TO", to);
		},
		save() {
			var self = this;
			self.$store
				.dispatch("organisation/saveConfig", self.config)
				.then(() => {
					self.saved = true;
				});
		},
	},
	created() {
		var self = this;
		self.$store.dispatch("startConfig").then(() => {
			self.start(this.firstDepartment);
		});
		self.$root.$on("saved", () => {
			this.saved = true
		} );
		this.$store.dispatch("tags/addAllToFilter")
	},
};
//
</script>
"
