<template>
	<v-item v-slot="{ active, toggle }" :value="id">
		<v-list-item @click="toggle" :disabled="started">
			<v-list-item-content>
				<v-list-item-title>
					{{department.name}}
				</v-list-item-title>
				<v-list-item-subtitle>
					{{department.type}}
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-icon>
				<v-icon large :color="active ? 'green' : 'secondary'">
					{{ active? 'mdi-check-circle' : 'mdi-close-circle' }}
				</v-icon>
			</v-list-item-icon>
		</v-list-item>
	</v-item>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
// A single department to select / deselect
// @group Config
export default {
	name: "DepartmentSelectionListItem",
	props: {
		//the id of the department being passed
		id: { type: String }, 
	},
	computed: {
		...Vuex.mapState({
			sections: (state) => state.sections.data,
			sectionResponses: (state) => state.sectionResponses.data,
			organisation: (state) => state.organisation.data,
		}),
		
		department() {
			return this.$store.state.departments.data[this.id];
		},
		section() {
			var section = this.department.section;
			if (!section) {
				return false;
			}
			return this.$store.state.sections.data[section] || false;
		},
		responsesToThisDepartment(){
			let id = this.id;
			let responses = Object.values(this.sectionResponses);
			return responses.filter( a => a.department == id );
		},
		started() {
			let match = this.responsesToThisDepartment.find(
				(a) => ["complete", "delegated", "returned"].includes(a.status)
			);
			return match ? true : false;
		},
	},
}//
</script>
"
