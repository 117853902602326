<template>
    <v-chip v-if="tag" x-small color="accent" class="mr-2">{{tag.name}}</v-chip>
</template>

<script>
// Displays a tag chip
// @group Config

export default {
    name: "Tag",
    props: {
        // the ID of the tag 
        id: { type: String } 
    }, 
    computed: {
        tag(){
            return this.$store.state.tags.data[this.id]
        }
    }
}
</script>