<template>
	<div v-if="part">
		<section-config-toolbar
			:text="partName"
			:value="currentPart"
			:array="partOrder"
			@click="(d) => changePart(d)"
		>
		</section-config-toolbar>
		<v-card-text v-if="part">
			<v-list>
				<template v-for="goal in filteredGoals">
					<goal-config
						@error="error = true"
						:key="goal.id"
						:id="goal.id"
					></goal-config>
				</template>
			</v-list>
			<p>
				<v-btn
					:disabled="disabled"
					small
					text
					color="ig-black"
					@click="toggleAll"
					>{{
						hasAll
							? $t("config.goals.deselect_all")
							: $t("config.goals.select_all")
					}}</v-btn
				>
				<v-dialog
					v-model="dialog"
					content-class="mw-medium-dialog"
				>
					<template v-slot:activator="{ on }">
						<v-btn
							:disabled="disabled"
							small
							text
							color="ig-black"
							v-on="on"
							>{{ $t("goals.nav.new") }}</v-btn
						>
					</template>
					<new-goal :exclude="config" @new="addGoal"></new-goal>
				</v-dialog>
			</p>
		</v-card-text>
		<error-message
			v-if="disabled"
			v-model="error"
			:message="errorMessage"
		></error-message>
	</div>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
import SectionConfigToolbar from "@c/config/SectionConfigToolbar";
import GoalConfig from "@c/config/GoalConfig";
import NewGoal from "@c/config/NewGoal";
import ErrorMessage from "@c/notices/ErrorMessage";
// Lists all goals in the config for a single sectionPart 
// @group Config
export default {
	name: "PartConfig",
	props: {
	},
	data: () => {
		return {
			error: false,
			dialog: false,
		};
	},
	components: {
		SectionConfigToolbar,
		GoalConfig,
		ErrorMessage,
		NewGoal,
	},
	computed: {
		...Vuex.mapState({
			currentDepartment: (state) => state.current.department,
			currentPart: (state) => state.current.part,
			goalGroups: (state) => state.goalGroups.data,
			goals: (state) => state.goals.data,
			sections: (state) => state.sections.data,
		}),
		filter(){
			return this.$store.state.tag_filter;
		},
		part() {
			return this.$store.state.sectionParts.data[this.currentPart];
		},
		partGoals(){
				return this.part.goals || [];
		},
		partOrder() {
			return this.$store.getters.defaultPartOrder[this.section.id] || [];
		},
		config() {
			var self = this;
			var config =
				self.$store.getters["organisation/config"][self.currentDepartment][
					this.currentPart
				] || [];
			return config.filter((id) => self.goals[id]);
		},
		section() {
			var id = this.$store.getters.currentSection;
			return this.sections[id];
		},
		department() {
			return this.$store.state.departments.data[this.currentDepartment];
		},
		disabled() {
			return this.$store.getters.currentlyDisabled;
		},
		errorMessage() {
			var status = this.$store.getters.currentStatus;
			if (status == "delegated") {
				return this.$t("misc.delegation_warning");
			}
			if (status == "complete") {
				return this.$t("misc.completion_warning");
			}
			return false;
		},
		goalGroupOrder() {
			return this.$store.getters["goalGroups/defaultOrder"];
		},
		partName() {
			var goalGroup = this.goalGroups[this.part.goalGroup];
			return goalGroup ? goalGroup.name : "";
		},
		goalsInConfig() {
			// the goal objects that will appear in the section part
			const self = this;
			return self.config.map((id) => self.goals[id]);
		},
		defaultGoals() {
			// goals that appear in the section part by default
			// but aren't deleted!
			const self = this;
			return self.partGoals.map((g) => self.goals[g]).filter((g) => g);
		},
		customGoals() {
			// goals that the respondent has added to the section part
			const self = this;
			return self.config.filter((g) => !self.partGoals.includes(g));
		},
		customGoalsAndDefaultGoals() {
			const self = this;
			var goals = [...self.defaultGoals];
			return goals.concat(
				self.customGoals.filter((g) => g).map((a) => self.goals[a])
			);
		},
		filteredGoals() {
			const self = this;
			if (!self.filter.length) {
				return this.customGoalsAndDefaultGoals;
			}
			return [...self.defaultGoals]
				.filter((g) => {
					var match = self.filter.filter((x) => {
						return g.tags && g.tags.includes(x);
					});
					return match.length;
				})
				.concat(
					self.customGoals.filter((g) => g).map((a) => self.goals[a])
				);
		},
		hiddenGoals(){
			let all = this.customGoalsAndDefaultGoals.map( a => a.id);
			let shown = this.filteredGoals.map( a => a.id);

			let hidden = all.filter( a => !shown.includes(a) );
			return hidden;
		},	
		hasAll() {
			var self = this;
			var diff = self.customGoalsAndDefaultGoals
				.map((g) => g.id)
				.filter((d) => !this.config.includes(d));
			return diff.length == 0;
		},
	},
	// watch: {
	// 	filter(){
	// 		const self = this;
	// 		self.filteredGoals.forEach( g => {
	// 			self.addGoal(g.id);
	// 		})
	// 	}
	// }, 
	methods: {
		changePart(part) {
			this.$store.commit("GO_TO", { part });
		},
		toggleAll() {
			const self = this;
			if (self.hasAll) {
				self.$store.dispatch("organisation/patch", {
					assessmentConfig: {
						sections: {
							[self.currentDepartment]: { [self.currentPart]: [] },
						},
					},
				});
			} else {
				// addAll
				var add = self.customGoalsAndDefaultGoals.map((g) => g.id);
				add = add.filter( a => a );
				self.$store.dispatch("organisation/patch", {
					assessmentConfig: {
						sections: {
							[self.currentDepartment]: { [self.currentPart]: add },
						},
					},
				});
			}
		},
		removeGoal(goal) {
			var self = this;
			var department = self.currentDepartment;
			var part = self.currentPart;
			self.$store
				.dispatch("organisation/removeGoalFromConfig", {
					part,
					goal,
					department,
				})
				.then(() => {
					self.dialog = false;
				});
		},
		addGoal(goal) {
			const self = this;
			var department = self.currentDepartment;
			var part = self.currentPart;
			self.$store
				.dispatch("organisation/addGoalToConfig", {
					part,
					department,
					goal,
				})
				.then(() => {
					self.dialog = false;
				});
		},
	},
	// created(){

	// }
};
//
</script>
"
