<template>
	<v-toolbar flat>
		<direction-button
			back
			:array="array"
			:value="value"
			@click="(d) => $emit('click', d)"
		></direction-button>
		<span :class="{ title: title }">{{ text }}</span>
		<slot name="badge"></slot>
		<v-spacer></v-spacer>
		<direction-button
			forward
			:array="array"
			:value="value"
			@click="(d) => $emit('click', d)"
		></direction-button>
	</v-toolbar>
</template>

<style lang="less"></style>

<script type="text/javascript">
import DirectionButton from "@c/buttons/DirectionButton";
// Displays a toolbar headline with buttons for navigating through the config
// @group Config
export default {
	name: "SectionConfigToolbar",
	props: {
		text: { type: String }, //the title for the toolbar
		title: { type: Boolean },
		array: {},
		value: {},
	},
	data: () => {
		return {};
	},
	components: {
		DirectionButton,
	},
	// computed: {
	// ...Vuex.mapState({})
	// },
	// methods: {
	// }
	// created(){

	// }
};
//
</script>
"
