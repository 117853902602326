<template>
	<v-sheet flat>
		<p>{{ $t("config.intro") }}</p>
		<v-btn color="primary" to="/assessment-config/departments">{{
			$t("config.nav.department_setup")
		}}</v-btn>
	</v-sheet>
</template>

<style lang="less">
.v-stepper__step {
	cursor: pointer;
}

.assessment-config {
	box-shadow: none;
}
</style>

<script type="text/javascript">
// The wrapper for the main assessment config
// @group Config
export default {
	name: "AssessmentConfig",
	data: () => {
		return {
			saved: false,
			step: 1,
			selectedDepartments: [],
			departmentConfig: {},
		};
	},
	created() {
		this.$store.dispatch("startConfig");
	},
};
//
</script>
"
