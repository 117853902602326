<template>
	<v-list-item class="clickable" dense :class="classes">
		<v-list-item-icon>
			<v-btn icon @click="toggleGoal">
				<v-icon v-if="selected" color="green"
					>mdi-checkbox-marked-outline</v-icon
				>
				<v-icon v-if="!selected" color="grey"
					>mdi-checkbox-blank-outline</v-icon
				>
			</v-btn>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title class="pr-4">
				{{ goal.name }}
			</v-list-item-title>
			<v-list-item-subtitle v-if="goal.tags">
				<tag v-for="tag in goal.tags" :key="tag" :id="tag"></tag>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-badge
			color="green"
			class="white--text mr-4"
			:content="goalFrequency"
		></v-badge>
		<v-list-item-action>
			<ConfigGoalMultiple :id="id"></ConfigGoalMultiple>
		</v-list-item-action>
	</v-list-item>
</template>

<style lang="less">
@import "./../../assets/css/variables";
.clickable {
	cursor: pointer;
	margin-bottom: 3px;
	border: 1px solid @secondary;
}
.selected {
	border-left: 8px solid @ig-green;
}
.deselected {
	border-left: 5px solid @secondary;
}
</style>

<script type="text/javascript">
import Vuex from "vuex";
import Tag from "@c/config/Tag";
import ConfigGoalMultiple from "@c/config/ConfigGoalMultiple";
// Selects / deselects a single goal from the config 
// @group Config
export default {
	name: "GoalConfig",
	props: {
		// The id of the goal document
		id: { type: String },
	},
	data: () => {
		return {};
	},
	components: {
		ConfigGoalMultiple,
		Tag
	},
	computed: {
		...Vuex.mapState({
			goal(state) {
				var id = this.id;
				return state.goals.data[id];
			},
			department: (state) => state.current.department,
			part: (state) => state.current.part,
		}),
		config() {
			return this.$store.getters["organisation/config"];
		},
		flatConfig() {
			return this.$store.getters.viewOrder;
		},
		partConfig() {
			return this.config[this.department][this.part] || [];
		},
		disabled() {
			return this.$store.getters.currentlyDisabled;
		},
		selected() {
			return this.partConfig.includes(this.id);
		},
		classes() {
			return {
				selected: this.selected,
				deselected: !this.selected,
			};
		},
		goalFrequency() {
			var id = this.id;
			return this.flatConfig.filter((m) => m.goal == id).length || "0";
		},
	},
	methods: {
		toggleGoal() {
			if (this.disabled) {
				this.$emit("error");
				return;
			}
			if (this.selected) {
				this.$store.dispatch("organisation/removeGoalFromConfig", {
					goal: this.id,
					department: this.department,
					part: this.part,
				}).then(  () => {
					this.$root.$emit("saved");
				})
			} else {
				this.$store.dispatch("organisation/addGoalToConfig", {
					goal: this.id,
					department: this.department,
					part: this.part,
				}).then(  () => {
					this.$root.$emit("saved");
				})
			}
		},
	},
	// created(){

	// }
};
//
</script>
"
